<template>
  <div>
    <v-toolbar elevation="0" height=90>
      <v-btn color="#00a0e0" class="d-none d-md-flex" text icon @click="toggleMute">
        <template v-if="!this.muted">
          <v-icon v-if="this.volume >= 0.5">mdi-volume-high</v-icon>
          <v-icon v-else-if="this.volume > 0">mdi-volume-medium</v-icon>
          <v-icon v-else>mdi-volume-low</v-icon>
        </template>
        <v-icon v-show="this.muted">mdi-volume-off</v-icon>
      </v-btn>
      <v-slider color="#00a0e0" class="d-none d-md-flex" v-model="volume" @input="updateVolume(volume)" max="1" step="0.1"></v-slider>
      <p class="d-none d-md-flex vol">{{ this.volume * 100 + '%' }}</p>
      <v-spacer></v-spacer>
      <v-btn class="control_btn" outlined fab small color="#00a0e0" @click="skipTrack('prev')">
        <v-icon>mdi-skip-previous</v-icon>
      </v-btn>
      <v-btn class="control_btn" outlined fab small color="#00a0e0" @click="stopTrack">
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-btn class="control_btn" v-if="!playing" outlined fab small color="#00a0e0" @click="playTrack()">
        <v-icon>mdi-play</v-icon>
      </v-btn>
      <v-btn class="control_btn" v-else outlined fab small color="#00a0e0" @click="pauseTrack">
        <v-icon>mdi-pause</v-icon>
      </v-btn>
      <v-btn class="control_btn" outlined fab small color="#00a0e0" @click="skipTrack('next')">
        <v-icon>mdi-skip-next</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text icon @click="toggleLoop">
        <v-icon color="#00a0e0" v-if="this.loop">mdi-repeat-once</v-icon>
        <v-icon color="blue-grey" v-else>mdi-repeat-once</v-icon>
      </v-btn>
      <v-btn text icon @click="toggleShuffle">
        <v-icon color="#00a0e0" v-if="this.shuffle">mdi-shuffle</v-icon>
        <v-icon color="blue-grey" v-else>mdi-shuffle</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar class="float-right d-xs-flex d-sm-flex d-md-none" elevation="0" height="15">
      <p id="song_time">{{ trackInfo.seek | minutes }}/{{ trackInfo.duration | minutes }}</p>
    </v-toolbar>
    <br>
    <v-toolbar elevation="0" height="15">
      <v-progress-linear color="#00a0e0" rounded height="15" v-model="trackProgress"  @change="updateSeek($event)"></v-progress-linear> 
    </v-toolbar>
  </div>
</template>

<script>
  import {Howler} from 'howler'

  export default {
    props: {
      loop: Boolean,
      shuffle: Boolean,
      progress: Number,
      playing: Boolean,
      trackInfo: Object
    },
    data () {
      return {
        volume: 1,
        muted: false
      }
    },
    computed: {
      trackProgress: {
        // getter
        get() {
          return this.progress * 100
        },
        // setter
        set(newValue) {
          newValue;
        }
      },
    },
    created: function () {
      Howler.volume(this.volume)
    },
    methods: {
      playTrack(index) {
        this.$emit('playtrack', index)
      },
      pauseTrack() {
        this.$emit('pausetrack')
      },
      stopTrack() {
        this.$emit('stoptrack')
      },
      skipTrack (direction) {
          this.$emit('skiptrack', direction)
      },
      updateVolume (volume) {
        Howler.volume(volume)
      },
      toggleMute () {
        Howler.mute(!this.muted)
        this.muted = !this.muted
      },
      toggleLoop () {
        this.$emit('toggleloop', !this.loop)
      },
      toggleShuffle () {
        this.$emit('toggleshuffle', !this.shuffle)
      },
      updateSeek (percent) {
        this.$emit('updateseek', percent)
      }
    } 
  }
</script>

<style scoped>
.v-toolbar .v-input{
    padding-top: 20px !important;
    /* width: 100%; */
}

.vol {
  padding-top: 15px;
}

#song_time{
  margin-bottom: 0px;  
  color: grey !important;
}

.control_btn{
  margin-left: 5px !important;
  margin-right: 5px !important;
}
</style>