<template>
  <v-card elevation="0" height="48vh" :class="{ playlist }">
    <v-list style="width: 100%;">
      <v-list-item
        v-for="(track, index) in playlist"
        :key="track.title"
        v-show="track.display"
        @click="selectTrack(track, index)"
        @dblclick="playTrack()"
        :class="[
          { selected: track === selectedTrack },
          { even: index % 2 == 0 },
        ]"
      >
        <v-hover v-slot="{ hover }">
          <v-list-item-content>
            <v-list-item-title>
              <div class="song_index">
                <p class="track_index" v-if="!hover && !track.howl.playing()">{{ parseInt(index+1) }}</p>
                <v-icon left v-else-if="!hover && track.howl.playing()">mdi-poll</v-icon>
                <v-icon left v-else-if="hover && !track.howl.playing()" @click="playTrack(index)">mdi-play</v-icon>
                <v-icon left v-else-if="hover && track.howl.playing()" @click="pauseTrack">mdi-pause</v-icon>
              </div>
              <div class="song_info">
                <v-avatar class="ml-5 mr-3 " tile v-if="track.img">
                  <img :src="track.img" />
                </v-avatar>
                <v-avatar class="ml-5 mr-3 " tile v-else color="#00a0e0">
                  <v-icon dark> mdi-music-circle </v-icon>
                </v-avatar>
                <div class="track_info ">
                  <p class="song_title">{{ track.title }}</p>
                  <p class="song_artist">{{ track.artist }}</p>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-hover>
        <div>{{ track.howl.duration() | minutes }}</div>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    playlist: Array,
    selectedTrack: Object,
  },

  methods: {
    selectTrack(track) {
      this.$emit("selecttrack", track);
    },
    playTrack(index) {
      this.$emit("playtrack", index);
    },
    pauseTrack() {
      this.$emit("pausetrack");
    }
  },
};
</script>

<style scoped>
.selected {
  background-color: lightblue !important;
}
/* .even {
    background-color: #dcdcdc
  } */
.playlist {
  overflow: auto;
  /* margin-top: 10px */
}
.v-list-item:hover {
  background-color: #e5e5e5;
}
.song_index{
  display: inline-block !important;
  width: 20px;
}
.song_info{
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
}
.track_index{
  align-content: center !important;
  padding-left: 8px;
}
.icon_index .v-icon{
  padding-right: 15px I !important;
}
.track_info {
  display: inline-block !important; 
  vertical-align: middle !important;
}
.song_title {
  font-size: 1.2rem !important;
  margin-bottom: 5px !important;
}
.song_artist{
  color: grey !important;
  margin-bottom: 2px !important;
}
@media screen and (max-width:959px) {
  .song_index {
    display:none;
  }
  .v-avatar {
    margin-left: 0px !important;
  }
}
</style>