<template>
  <v-card elevation="0" height="100">
    <v-card-title>
      <v-avatar size="80" class="ml-3 mr-3" tile v-if="trackInfo.image">
        <img :src="trackInfo.image" />
      </v-avatar>
      <v-avatar size="80" class="ml-3 mr-3" tile v-else color="#00a0e0">
        <v-icon dark> mdi-music-circle </v-icon>
      </v-avatar>
      <div class="track_info">
        <h2 class="song_title d-none d-sm-flex">{{ trackInfo.title }}</h2>
        <h5 class="song_artist d-none d-sm-flex">{{ trackInfo.artist }}</h5>
        <h4 class="song_title d-xs-flex d-md-none">{{ trackInfo.title }}</h4>
        <h6 class="song_artist d-xs-flex d-md-none">{{ trackInfo.artist }}</h6>
      </div>
      <!-- <h2>{{ trackInfo.title }}</h2> -->
      <v-spacer></v-spacer>
      <h3 class="d-none d-md-flex">{{ trackInfo.seek | minutes }}/{{ trackInfo.duration | minutes }}</h3>
    </v-card-title>    
  </v-card>
</template>

<script>

  export default {
    props: {
      trackInfo: Object
    },
  }
</script>

<style scoped>
.track_info {
  display: inline-block !important; 
  vertical-align: middle !important;
  max-width: 60% !important;
}
.song_title {
  margin-bottom: 5px !important;
  white-space: initial;
  word-break: normal !important;
}
.song_artist{
  color: grey !important;
  margin-bottom: 2px !important;
  word-break: normal !important;
}
</style>